@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
  font-family: 'Poppins', sans-serif !important;
}

.hero-section {
  background: linear-gradient(151.97deg, #9A53FF -9.12%, #000000 24.18%, #000000 90.32%);
  box-shadow: 0px 4.36111px 92.6736px 26.1667px rgba(0, 0, 0, 0.2);
}

.text-gradient {
  background: linear-gradient(270deg, #FE8888 27.16%, #8066FF 97.86%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section7-card-heading {
  background: linear-gradient(0deg, #000000, #000000), linear-gradient(90deg, rgba(231, 228, 248, 0.239583) 7.72%, #E7E4F8 132.48%);
  border: 0.59px solid;
  border-image-source: linear-gradient(90deg, rgba(231, 228, 248, 0.239583) 7.72%, #E7E4F8 132.48%);
  letter-spacing: -9.48954px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.section7-card-heading-last {
  background: linear-gradient(0deg, #000000, #000000), linear-gradient(90deg, #E7E4F8 7.72%, rgba(231, 228, 248, 0) 132.48%);
  border: 0.59px solid;
  border-image-source: linear-gradient(90deg, #E7E4F8 7.72%, rgba(231, 228, 248, 0) 132.48%);
  letter-spacing: -9.48954px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 768px) {
  .section7-card-heading, .section7-card-heading-last {
    letter-spacing: -7px;
  }
}

.section7 {
  box-shadow: 0px 4.361111164093018px 92.67361450195312px 26.166667938232422px #00000033;
}

.section7-card {
  border: 1.74px solid;
  border-image-source: linear-gradient(270deg, #BC76C6 27.16%, #8066FF 97.86%), linear-gradient(270deg, #FE8888 27.16%, #8066FF 97.86%);
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */